<template>
  <div class="map" v-loading="loading">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="page_head">
      <el-breadcrumb-item :to="{ path: '/Master' }">竞赛管理</el-breadcrumb-item>
      <el-breadcrumb-item>监考视频上传</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="top-wrap">
      <div class="formItem">
        <p class="title"><b>监考视频上传</b></p>
        <div class="itemWrap">
          <div class="listItem">
            <div class="item iTitle icon-label">监考视频地点(学校全称)</div>
            <div class="item"><el-input v-model="school" placeholder="请输入学校名称"> </el-input></div>
            <div class="item iTitle icon-label">参赛人数</div>
            <div class="item"><el-input v-model="number" oninput="value=value.replace(/[^\d]/g,'')"
                placeholder="请输入参赛人数"></el-input></div>
          </div>

          <div class="listItem">
            <!-- <div class="item iTitle">纸质发票/电子发票</div> -->
            <div class="item iTitle icon-label" style="border-bottom: 1px solid #ecedf0;">选择参赛类型（省赛/国赛）</div>
            <div class="item" style="border-bottom: 1px solid #ecedf0;">
              <el-select v-model="type" placeholder="请选择" style="width: 2.48rem;">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="item iTitle icon-label-s" style="border-bottom: 1px solid #ecedf0;">备注</div>
            <div class="item" style="border-bottom: 1px solid #ecedf0;"><el-input v-model="memo"
                placeholder="请输入内容"></el-input></div>
          </div>
        </div>
      </div>
    </div>
    <iframe v-show="type == '省赛'" style="border: none;" src="/static/oss_upload/upload1.html" ref="iframe" width="100%"
      height="200px" scrolling="no"></iframe>
    <iframe v-show="type == '国赛'" style="border: none;" src="/static/oss_upload/upload2.html" ref="iframe" width="100%"
      height="200px" scrolling="no"></iframe>

    <div class="step">
      <span class="step_s"  @click="nextStep(1)">提交</span>
      <span id="postfiles" @click="record()">上传记录>>></span>
    </div>

    <!-- 弹出框 -->
    <div>
      <el-dialog v-model="dialogSwitch" title="上传文件记录" width="70%">
        <el-table :data="data" style="width: 100%" :default-sort="{ prop: 'date', order: 'descending' }">
          <el-table-column prop="create_time" label="时间" sortable width="250">
          </el-table-column>

            <el-table-column prop="file_name" label="文件名称" sortable width="400">
              <template #default="scope">
                <el-tooltip class="item" effect="dark" :content= scope.row.file_name placement="top-start">
                  <span class="text-ellipsis">{{ scope.row.file_name }}</span>
                </el-tooltip>
              </template>
            </el-table-column>

          <el-table-column prop="exam_place" label="学校名称" sortable>
          </el-table-column>
          <el-table-column prop="exam_type" label="参赛类型" sortable>
          </el-table-column>
          <el-table-column prop="people_count" label="人数" sortable>
          </el-table-column>
          <el-table-column prop="memo" label="备注" sortable>
          </el-table-column>
        </el-table>
        <el-pagination class="Page" background @current-change="handleCurrentChange" :page-size="10"
          :hide-on-single-page="value" layout="prev, pager, next" :total="total">
        </el-pagination>
      </el-dialog>
    </div>
  </div>
</template>
  
<script>
// @ is an alias to /src
import $ from "jquery";
import { userInfo } from "api/apis.js";
import { time } from "console";


export default {
  name: 'Home',
  components: {

  },
  data() {
    return {
      options: [{
        value: '省赛',
        label: '省赛'
      }, {
        value: '国赛',
        label: '国赛'
      }],
      data: "",
      type: '',
      number: '',
      school: '',
      memo: '',
      total: "",
      page: "1",
      limit: "",
      value: "",
      name: [],
      url: [],
      fileTotal:0,
      fileSuccess:0,
      dialogSwitch: false,
      loading:false,
      timer:null
    }
  },
  mounted() {
    let dataobj = new FormData();
    dataobj.append("user_id", JSON.parse(localStorage.getItem('UPuser')).user_id);
    userInfo.menu_power(dataobj).then(res => {
      // 省赛是否可以上传
      console.log(res.data.video_province_submit_sign,'省赛')

      if (res.data.video_province_submit_sign == true) {
        this.options = [{
          value: '省赛',
          label: '省赛'
        }]
      }
      console.log(res.data.video_country_submit_sign,'国赛')
      // 国赛是否可以上传
      if (res.data.video_country_submit_sign == true) {
        this.options = [{
          value: '国赛',
          label: '国赛'
        }]
      }

      if(res.data.video_country_submit_sign == false && res.data.video_province_submit_sign == false){
        this.type = "暂未开始"
        this.options = [{
        }]
      }

      if(res.data.video_country_submit_sign == true && res.data.video_province_submit_sign == true){
        this.type = ""
        this.options = [{
        value: '省赛',
        label: '省赛'
      }, {
        value: '国赛',
        label: '国赛'
      }]
      }
    })
  },
  created() {
    var _this = this;
    //这里可放到全局，提供给子 iframe 调用
    window.addTab = function (name, url,fileTotal,fileSuccess) {
      console.log(url)
      console.log(name)
      _this.name.push(name)
      _this.url.push(url)
      _this.fileTotal = fileTotal
      _this.fileSuccess = fileSuccess
    };
    window.addrecord = function () {
      _this.record()
    };
  },
  methods: {
    reload(){
      location.reload()
      this.timer = null
    },
    // 上传记录
    record() {
      this.dialogSwitch = true;
      let uid = this.$store.state.User.user_id
      let data = new FormData();
      data.append("user_id", uid);//用户ID
      data.append("page", this.page);
      data.append("limit", 10);
      userInfo.video_select(data).then(res => {
        let dataobj = JSON.parse(res.data)
        console.log(dataobj)
        dataobj.map((item, index) => {
          // item.file_name = item.file_name.replace(/\[|]/g,'')
          item.file_name = JSON.parse(item.file_name).join(',')
          return dataobj
          console.log(dataobj)
        });
        this.data = dataobj
        this.total = res.count
      })
    },
        // 点击分页
    handleCurrentChange(val) {
      this.page = val
      this.record()
    },
    // 提交
    nextStep() {
      if(this.type == '暂未开始'){
        this.$message.error('上传暂未开始！');
        return false
      }
      if(this.school == '') {
        this.$message.error('请输入监考视频地点!');  
        return false
      }
      if(this.number == '') {
        this.$message.error('请输入参赛人数!');  
        return false
      }
      if(this.name == '') {
        this.$message.error('请上传文件!');  
        return false
      }
      this.$confirm( "已选择文件: " + this.fileTotal + "<br>已上传文件: " + this.fileSuccess + '<br>是否提交' , '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning', 
          center: true
        }).then(() => {
        let uid = this.$store.state.User.user_id
        let dataobj = new FormData();
        dataobj.append("user_id", uid);//用户ID
        dataobj.append("file_name", JSON.stringify(this.name));//文件名
        dataobj.append("url", JSON.stringify(this.url));//文件路径
        dataobj.append("exam_place", this.school);//学校
        dataobj.append("people_count", this.number);//人数
        dataobj.append("exam_type", this.type);//类型
        dataobj.append("memo", this.memo);//备注
        userInfo.video_record(dataobj).then(res => {
          if (res.code == 0) {
            this.$message.error(res.msg);
          } else {
            this.$message({
              message: '提交成功!',
              type: 'success',
              duration: 3000
            });
            this.loading = true
            this.timer = setTimeout(this.reload,3000)
          }
        })
        }).catch(() => {
          return false        
        });
    
      }
    },
}
</script>
  
<style lang="scss" scoped>
:deep .el-dialog__header {
  background: #d7edfb !important;
  margin-right: 0 !important;
}

@import './invigilation.scss'
</style>